import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../SEO"
import Layout from "../components/layout"
import Fade from "react-reveal/Fade"
import styles from "./scss/work.module.scss"
const work = props => {
  return (
    <>
      <SEO title="Robbie Galvin | Work" />
      <Layout>
        <section className={styles.work}>
          <Fade duration={2500}>
            <h2>{props.data.contentfulWorkPage.title}</h2>
            <p>{props.data.contentfulWorkPage.body}</p>
          </Fade>
          <div className={styles.articles}>
            <ul>
              {props.data.allContentfulArticle.edges.map((edge, index) => (
                <Fade duration={2500} delay={index * 100} key={index}>
                  <li>
                    <a
                      href={edge.node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>{edge.node.title}</h3>

                      {documentToReactComponents(edge.node.description.json)}
                    </a>
                  </li>
                </Fade>
              ))}
            </ul>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default work

export const query = graphql`
  {
    contentfulWorkPage {
      title
      body
    }
    allContentfulArticle(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          description {
            json
          }
          date
          link
          featured
        }
      }
    }
  }
`
